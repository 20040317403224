"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import Cookies from "js-cookie";
import { supabaseClient } from "@utility/supabase-client";
export default function AuthGuard({
  children
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();

  // If we're on the login page, just render children without any auth checks
  if (pathname === "/login") {
    return <>{children}</>;
  }
  const checkUserAndHandle = async () => {
    try {
      const {
        data: {
          user
        },
        error
      } = await supabaseClient.auth.getUser();
      if (error || !user) {
        Cookies.remove("token");
        window.location.href = "/login";
        return;
      }
    } catch (err) {
      console.error("Auth check failed:", err);
      Cookies.remove("token");
      window.location.href = "/login";
    }
  };
  useEffect(() => {
    checkUserAndHandle();
    const {
      data: {
        subscription
      }
    } = supabaseClient.auth.onAuthStateChange(event => {
      checkUserAndHandle();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return <>{children}</>;
}